import { useState, useEffect } from 'react';

const checkIsMobile = () => {
  const userAgent = typeof window.navigator !== 'undefined' ? window.navigator.userAgent : '';
  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  return mobile;
};

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(checkIsMobile());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};
