import React, { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { ACLayout } from 'shared/components';
import StageValidatorGuard from 'shared/guard/stage-validator.guard';

const EntitySelectionPage = lazy(() => import('./pages/entitySelection'));
const PrimaryPage = lazy(() => import('./pages/primary'));
const ContactPage = lazy(() => import('./pages/contact'));
const LimitOfCoverPage = lazy(() => import('./pages/limitOfCover'));
const GroupTurnoverPage = lazy(() => import('./pages/groupTurnover'));
const AdditionalIndividualSelectionPage = lazy(
  () => import('./pages/additionalIndividualSelection')
);
const AdditionalIndividualEntitiesPage = lazy(() => import('./pages/additionalIndividualEntities'));
const AdditionalTrustSelectionPage = lazy(() => import('./pages/additionalTrustSelection'));
const AdditionalTrustEntitiesPage = lazy(() => import('./pages/additionalTrustEntities'));
const AdditionalBusinessSelectionPage = lazy(() => import('./pages/additionalBusinessSelection'));
const AdditionalBusinessEntitiesPage = lazy(() => import('./pages/additionalBusinessEntities'));
const AdditionalTrusteeSelectionPage = lazy(() => import('./pages/additionalTrusteeSelection'));
const AdditionalTrusteeEntitiesPage = lazy(() => import('./pages/additionalTrusteeEntities'));
const BusinessPage = lazy(() => import('./pages/business'));
const CompletePage = lazy(() => import('./pages/complete'));
const PaymentPage = lazy(() => import('./pages/payment'));
const SummaryPage = lazy(() => import('./pages/summary'));
const PaymentOptionsPage = lazy(() => import('./pages/paymentOption'));
const MissingEntityTypesPage = lazy(() => import('./pages/missingEntityTypes'));

function App() {
  const routes = useRoutes([
    { path: '/', element: <ACLayout /> },
    {
      path: '/*',
      element: <ACLayout />,
      children: [
        { index: true, element: <EntitySelectionPage /> },
        {
          path: 'entity-selection/:id',
          element: (
            <StageValidatorGuard>
              <EntitySelectionPage />
            </StageValidatorGuard>
          ),
        },
        { path: 'primary/:entityType/:id', element: <PrimaryPage /> },
        { path: 'contact/:id', element: <ContactPage /> },
        { path: 'limitofcover/:id', element: <LimitOfCoverPage /> },
        { path: 'business-group-turnover/:id', element: <GroupTurnoverPage /> },
        {
          path: 'additional-individuals-selection/:id',
          element: <AdditionalIndividualSelectionPage />,
        },
        {
          path: 'additional-individuals-details/:id',
          element: <AdditionalIndividualEntitiesPage />,
        },
        {
          path: 'additional-trusts-selection/:id',
          element: <AdditionalTrustSelectionPage />,
        },
        {
          path: 'additional-trusts-details/:id',
          element: <AdditionalTrustEntitiesPage />,
        },
        {
          path: 'additional-businesses-selection/:id',
          element: <AdditionalBusinessSelectionPage />,
        },
        {
          path: 'additional-businesses-details/:id',
          element: <AdditionalBusinessEntitiesPage />,
        },
        {
          path: 'additional-trustee-selection/:id',
          element: <AdditionalTrusteeSelectionPage />,
        },
        {
          path: 'missing-entity-types/:id',
          element: <MissingEntityTypesPage />,
        },
        {
          path: 'additional-trustee-details/:id',
          element: <AdditionalTrusteeEntitiesPage />,
        },
        {
          path: 'business-entity/:id',
          element: <BusinessPage />,
        },
        {
          path: 'summary/:id',
          element: <SummaryPage />,
        },
        {
          path: 'payment-options/:id',
          element: <PaymentOptionsPage />,
        },
        {
          path: 'pay/:id',
          element: (
            <StageValidatorGuard>
              <PaymentPage />
            </StageValidatorGuard>
          ),
        },
        {
          path: 'paymentretry/:id',
          element: <PaymentPage />,
        },
        {
          path: 'complete/:id',
          element: <CompletePage />,
        },
        { path: '*', element: <Navigate to="/" replace /> },
      ],
    },
  ]);

  return <Suspense>{routes}</Suspense>;
}

export default App;
