import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import { UseFormRegister } from 'react-hook-form';

export interface ACInputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  label?: string;
  className?: string;
  register?: UseFormRegister<any>;
  errorMessage?: string;
  name?: string;
  icon?: React.ReactNode;
  isSummary?: boolean;
  onInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const ACInput = ({
  placeholder,
  label,
  className,
  register,
  errorMessage,
  name,
  icon,
  onInputChange,
  isSummary = false,
  ...props
}: ACInputProps) => {
  const inputClassName = `w-full px-4 py-2 border bg-[#f7f9fc] ${
    isSummary ? 'h-11 text-sm' : 'h-12 text-base'
  } border-gray-300 rounded-md text-[##333333] focus:bg-[#fff] focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-transparent placeholder:text-slate-400 font-medium pr-10 disabled:text-[#B1B1B1]`;

  return (
    <div className={`flex flex-col ${className}`}>
      {label && <div className="mb-2 font-semibold">{label}</div>}
      <div className="relative">
        <input
          type="text"
          placeholder={placeholder}
          className={inputClassName}
          spellCheck="false"
          {...(register && name ? register(name) : {})}
          onChange={async e => {
            if (register && name) {
              await register(name).onChange(e);
            }

            if (onInputChange) {
              onInputChange(e);
            }
          }}
          {...props}
        />
        {icon}
      </div>

      {errorMessage && (
        <div className={`text-[#d02b2b] font-normal ${isSummary ? 'text-sm' : 'text-base'} mt-1`}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};
