import React, { ReactNode } from 'react';
import type { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { DialogActions, Divider } from '@mui/material';

import { ACButton } from 'shared/components';

interface ACModalProps {
  content: ReactNode;
  title?: string;
  onClose?: () => void;
  open: boolean;
  size?: 'md' | 'sm';
  buttonText?: string;
  showDivider?: boolean;
  showCloseButton?: boolean;
  disableBackdropClick?: boolean;
}

export const ACModal: FC<ACModalProps> = ({
  size,
  content,
  title,
  onClose,
  open,
  buttonText = 'Close',
  showDivider = false,
  showCloseButton = true,
  disableBackdropClick = false,
}) => {
  const handleClose = (_event: React.MouseEvent<HTMLElement>, reason: string) => {
    if (reason !== 'backdropClick' && !disableBackdropClick) {
      onClose?.();
    }
  };

  return (
    <Dialog fullWidth maxWidth={size ? size : 'md'} onClose={handleClose} open={open}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          pt: 2,
        }}
      >
        <Typography variant="h5" className="text-base font-bold leading-normal text-neutral-800">
          {title}
        </Typography>

        {showCloseButton && (
          <IconButton color="inherit" onClick={onClose}>
            <SvgIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </SvgIcon>
          </IconButton>
        )}
      </Stack>

      {showDivider && <Divider className="mt-4" />}

      <DialogContent className="ta-tooltip-container">{content}</DialogContent>

      {showDivider && <Divider className="mb-5" />}

      <DialogActions className="mb-3">
        <ACButton type="button" size="sm" onClick={onClose}>
          {buttonText}
        </ACButton>
      </DialogActions>
    </Dialog>
  );
};
